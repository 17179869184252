
body {
  background: $body-bg;
}

.header-container {
  position: relative;

  &::before  {
    background: linear-gradient(88.13deg, $primary 0.71%, $primary-alt 98.72%);
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.form-group.is-valid label {
  color: #404253 !important;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background: none !important;
  border-color: #E5EAEE !important;
}

.bg-image-card {
  background-repeat: no-repeat;
  background-position: top right;
}

.grid-40 {
  display: grid;
  align-items: center;
  grid-template-columns: 40% 1fr;
}

.w-20 {
  width: 20%;
}

.w-10 {
  width: 10%;
}

.max-w-140px {
  max-width: 140px;
}

.max-w-180px {
  max-width: 180px;
}

.max-w-216px {
  max-width: 216px;
}

.max-w-75 {
  max-width: 75%;
}

.min-w-180px {
  min-width: 180px;
}

.text-48px {
  font-size: 48px;
}

.border-2 {
  border: 2px solid;
}

.text-success-alt {
  color: $success-alt;
}

h3.aside {
  font-size: 1.429rem;
  line-height: 2.143rem;
  color: #1E3C5B;
}

.aside-menu {
  select.form-control {
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    font-weight: 500;
    font-size: 0.857rem;
    line-height: 0.957rem;
    color: #3B77B5;
    margin-bottom: 2.5rem;
    background-image: url('../assets/iconSmall.png');
  }
  button {
    width: 100%;
    margin-top: 2rem;
  }
  .form-group {
    margin-bottom: 0;
    label.nutrition-serving-label {
      width: 40%;
      color: #536F8B;
      font-size: 1.143rem;
      @media (min-width: 1200px) {
        width: 70%;
      }
    }
  }
}

div.quill {
 margin-top: 1rem;
}

.d-ib {
  display: inline-block;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.row {
  .directions-label {
    label {
      font-weight: 600;
      font-size: 0.857rem;
      line-height: 1.286rem;
      color: #536F8B;
    }
  }
}

.custom-recipe-builder {
  .react-select__menu-list {
    background-color: white;
  }
  .dropzone-container > div {
    text-align: center;
  }
  div.jumbotron {
    text-align: left;
  }
  span.select__indicator-separator {
    display: none;
  }
  .select__value-container {
    margin-left: 25px;
  }
  .select__indicators {
    position: absolute;
    padding: 6px;
    div.select__indicator {
      padding: 0 !important;
    }
    .select__loading-indicator {
      margin-top: 10px;
    }
  }
  .foods-list {
    .hidden {
      display: none;
    }
    &.old-recipe {
      .ingredients-item {
        background-color: #8d8d8d;
      }
    }
    .div-label {
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #f64e60;
    }
    .old-recipe {
      display: block !important;
    }
    small {
      margin-left: 5px;
    }
    .ingredients-item {
      background-color: #326ba7;
      border-radius: 15px;
      span, label {
        color: white !important;
      }
      label {
        font-size: 0.813rem;
        line-height: 1.25rem;
        font-weight: 700;
      }
      span {
        font-size: 0.844rem !important;
        line-height: 0.875rem;
        font-weight: 500;
      }
      input.form-control {
        width: 40px;
        max-width: 40px;
        text-align: center;
        padding: 0;
        color: #3B77B5;
        @media (min-width: 768px) {
          width: 64px;
        }
      }
      .ingredients-list-drop {
        @media (max-width: 1200px) {
          min-width: 140px !important;
          width: 140px;
        }
      }
      .ingredients-list-drop > div > div {
        height: 48px;
        color: #3B77B5;
        > div {
          div:first-child {
            color: #3B77B5;
          }
        }
        div:nth-child(2) {
          > div {
            color: #3B77B5;
          }
        }
        span {
          display: none;
        }
      }
    }
  }
}