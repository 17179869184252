.table {
  font-size: $font-size-lg;

  thead {
    th {
      color: $dark-50;
      text-transform: uppercase;
    }
  }

  tbody tr {
    border-left: 12px solid transparent;

    @include hover() {
      color: $table-hover-color;
      border-color: $warning;
      cursor: pointer;
    }
  }
}