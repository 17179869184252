//
// Text
//

.text-decoration-underline { text-decoration: underline !important; }

.d-block-hover:hover {
  > .d-none{
    display: block !important;
  }
}

@media (min-width: 768px) {
  .d-md-grid {
    display: grid !important;
  }
  .desktop-l-auto {
    margin-left: auto !important;
  }
}