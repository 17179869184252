.modal {
  .modal-content {
    background-color: transparent;
  }

  .modal-header {
    color: white;
    background-color: $primary;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;

    .modal-title {
      color: white;
    }
  }
  .modal-body {
    max-height: 80vh;
    overflow-y: auto;
    background-color: white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .modal-open {
    overflow: hidden !important;
  }

  button.btn-close {
    border: 0;
    position: relative;
    background-color: transparent;

    &::after {
      top: 0;
      left: 0;
      width: 12px;
      height: 12px;
      content: '';
      position: absolute;
      background-repeat: no-repeat;
      background-image: url('../../assets/icons/close.svg');
    }
  }
}