// Font Weight
$font-weight-boldest:          	 700 !default;

$hr-border-color:                #D2E2F2 !important;

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

// Gray colors
$gray-darker:                   #464E5F !default;
$gray-dark:                     lighten($gray-darker, 20%) !default;
$gray-light:                    #979797 !default;

// Primary colors
$primary-darker:                #1C3B5C !default;

// Tables
$table-color:                   $primary-darker;

// Forms

$input-height:                  48px !default;
$input-height-sm:               34px !default;        
$input-height-lg:               56px !default;  
$input-padding-x:               2rem !default;
$input-padding-x-sm:            1.167rem !default;        
$input-padding-x-lg:            2rem !default;
$input-font-weight:             $font-weight-boldest !default;