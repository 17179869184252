.form-group {
	label {
		color: $dark-50;
	}
}

.form--size {
  .form-control {
    @extend .form-control;
    font-weight: $input-font-weight;
  }
  &-lg .form-control {
    @extend .form-control-lg
  }  
  &-sm .form-control {
    @extend .form-control-sm
  }
  &-jumbo .form-control {
    @extend .form-control-lg;
    
    height: 96px;
    font-size: 48px;
  }
}

.form--dark {
  .form-control {
    background-color: $gray-100;
  }
}

.form--primary {
  .form-control {
    color: $primary;
  }
}

.form--centered {
  .form-control {
    text-align: center;
  }
}

.form--no-margin {
  .form-group {
    margin: 0;
    
    label {
      margin: 0;
    }
  }
}

%grid-h-centered {
  display: grid;
  align-items: center;
}

.form--grid {
  .form-group {
    @extend %grid-h-centered;
    grid-template-columns: 160px 1fr;
  }

  &-small {
    .form-group {
      @extend %grid-h-centered;
      grid-template-columns: 80px 1fr;
    }
  }
}

.quill .ql-editor {
  min-height: 200px;
  background-color: white;
}

.label-fifty {
  min-width: 100px;
}