.card {
  @extend .gutter-b;

  border-radius: 12px;

  &--small {
    font-size: 12px;

    .card-body {
      padding: 1.25rem;
    }
  }

  &--minimal {
    font-size: 12px;

    .card-body {
      padding: 0.75rem;
    }
  }
}